import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import joan from "@images/joan.png"
import podcast from "@images/spot-on-logo.png"
import book from "@images/nutrition-you-book.jpg"

// import { StaticImage } from "gatsby-plugin-image"

// import { ResourcesData } from "../components/resources-content/resources-data"

import marked from "marked"

const Resources = ({ data, location }) => {
  const resources = data.allSanityResources.nodes

  return (
    <>
      <Layout location={location}>
        <Seo title="Featured Resources" />
        <section className="page-content resource-wrapper">
          <article>
            <div className="container">
              <h2 className="section-title text-center text-d-cyan mb-0">
                Nutrition Expertise & Leadership
              </h2>
              <div className="row mt-5">
                <div className="col-lg-6 d-inline align-items-center mx-auto">
                  <img src={joan} className="img-fluid mb-5" />
                  <h3 className="section-title text-d-cyan mb-0">Dr. Joan</h3>
                  <ul className="person-achievements mt-3">
                    <li>
                      <p>Conducted over 3,000 media interviews</p>
                    </li>

                    <li>
                      {" "}
                      <p>College textbook author</p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        Media Excellence Award recipient from the
                        <br /> Academy of Nutrition and Dietetics
                      </p>
                    </li>

                    <li>
                      {" "}
                      <p>
                        Host of the award-winning nutrition/health podcast,
                        Spot-On!
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 mx-auto">
                  {/* <li>
                        <p>
                          Co-Founder & Chief Nutrition Officer/<b>NuQ;</b>
                        </p>
                      </li>
                      <li>
                        <p>
                          Clinical Professor of Nutrition at{" "}
                          <b>Boston University</b>;
                        </p>
                      </li>
                      <li>
                        <p>
                          Author of
                          <a
                            href="https://www.pearson.com/store/p/nutrition-you/P100001167396/9780135217658?creative=545370970254&keyword=&matchtype=&network=g&device=c&gclid=CjwKCAiAksyNBhAPEiwAlDBeLPS89MZANxwgg0xat4MFDlKc_HUhNgB4sR546qbMF4qEbMRqaPqErhoCgccQAvD_BwE&gclsrc=aw.ds"
                            target="_blank"
                            className="mx-1"
                          >
                            Nutrition and You
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          Host of
                          <a
                            href="https://podcasts.apple.com/us/podcast/spot-on/id1448760100"
                            target="_blank"
                            className="mx-1"
                          >
                            Spot On Nutrition!
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          Conducted over <b>2,000 interviews</b> with nutrition
                          and health experts.
                        </p>
                      </li>
                      <li>
                        <p>
                          Recipient of
                          <strong className="mx-1">
                            2020 Media Excellence Award
                          </strong>{" "}
                          from the Academy of Nutrition and Dieticians (AND).
                        </p>
                      </li> */}

                  <div className="text-center">
                    <div className="row">
                      {/*   <a
                      href="http://blogs.bu.edu/salge/media/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://blogs.bu.edu/salge/media/
                    </a>*/}
                      <div className="col-md-12">
                        <img src={podcast} className="img-fluid mb-5 w-50" />
                      </div>
                      <div className="col-md-12">
                        <img src={book} className="img-fluid mb-5 w-50" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />

              <div className="row h-100">
                {resources.map(resource => (
                  <div className="col-lg-6 col-md-6 col-10 text-center h-auto mt-5 mx-lg-0 mx-md-0 mx-auto">
                    <div className="resources-box h-100">
                      <div className="pt-2 px-4">
                        <img
                          className="about-avocado img-fluid"
                          src={
                            resource.mainImage.asset.gatsbyImageData.images
                              .fallback.src
                          }
                          alt="Avocado"
                          style={{ height: "20vh", objectFit: "contain" }}
                        />
                      </div>
                      <h3 className="resource-name my-4 px-lg-4 px-md-4 px-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked(resource.title || ""),
                          }}
                        />
                      </h3>
                      <a
                        href={resource.readMoreLink}
                        className="resources-btn"
                        target="_blank"
                      >
                        Read More
                        <svg
                          className="d-none"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.827"
                          height="28.047"
                          viewBox="0 0 15.827 28.047"
                        >
                          <g
                            id="Component_43_1"
                            data-name="Component 43 – 1"
                            transform="translate(2 2)"
                          >
                            <g
                              id="Component_42"
                              data-name="Component 42"
                              transform="translate(16.18 -8.85) rotate(90)"
                            >
                              <path
                                id="Union_26"
                                data-name="Union 26"
                                d="M12.024,0,24.047,11.827Zm0,0L0,11.827Z"
                                transform="translate(8.85 4.353)"
                                fill="none"
                                stroke="#fff"
                                stroke-linejoin="round"
                                stroke-width="4"
                              />
                            </g>
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </article>
        </section>
      </Layout>
    </>
  )
}

export default Resources

export const ResourceQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityResources(sort: { order: DESC, fields: _updatedAt }) {
      nodes {
        id
        title
        slug {
          current
        }
        excerpt
        mainImage {
          asset {
            gatsbyImageData(
              fit: FILL
              placeholder: BLURRED
              sizes: ""
              layout: CONSTRAINED
            )
          }
        }
        body
        readMoreLink
      }
    }
  }
`
